import { render, staticRenderFns } from "./NodeIcon.vue?vue&type=template&id=788894ba"
import script from "./NodeIcon.vue?vue&type=script&lang=ts"
export * from "./NodeIcon.vue?vue&type=script&lang=ts"
import style0 from "./NodeIcon.vue?vue&type=style&index=0&id=788894ba&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports