var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.credentialTypesNodeDescriptionDisplayed.length)?_c('div',{class:_vm.$style.container},_vm._l((_vm.credentialTypesNodeDescriptionDisplayed),function(credentialTypeDescription){return _c('div',{key:credentialTypeDescription.name},[_c('n8n-input-label',{attrs:{"label":_vm.$locale.baseText(
				'nodeCredentials.credentialFor',
				{
					interpolate: {
						credentialType: _vm.credentialTypeNames[credentialTypeDescription.name]
					}
				}
			),"bold":false,"size":"small","set":_vm.issues = _vm.getIssues(credentialTypeDescription.name)}},[(_vm.isReadOnly)?_c('div',[_c('n8n-input',{attrs:{"disabled":"","value":_vm.selected && _vm.selected[credentialTypeDescription.name] && _vm.selected[credentialTypeDescription.name].name,"size":"small"}})],1):_c('div',{class:_vm.issues.length ? _vm.$style.hasIssues : _vm.$style.input},[_c('n8n-select',{attrs:{"value":_vm.getSelectedId(credentialTypeDescription.name),"placeholder":_vm.$locale.baseText('nodeCredentials.selectCredential'),"size":"small"},on:{"change":(value) => _vm.onCredentialSelected(credentialTypeDescription.name, value)}},[_vm._l((_vm.credentialOptions[credentialTypeDescription.name]),function(item){return _c('n8n-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),_c('n8n-option',{key:_vm.NEW_CREDENTIALS_TEXT,attrs:{"value":_vm.NEW_CREDENTIALS_TEXT,"label":_vm.NEW_CREDENTIALS_TEXT}})],2),(_vm.issues.length)?_c('div',{class:_vm.$style.warning},[_c('n8n-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(`${_vm.$locale.baseText('nodeCredentials.issues')}:<br />&nbsp;&nbsp;- ` + _vm.issues.join('<br />&nbsp;&nbsp;- '))},slot:"content"}),_c('font-awesome-icon',{attrs:{"icon":"exclamation-triangle"}})],1)],1):_vm._e(),(_vm.selected[credentialTypeDescription.name] && _vm.isCredentialExisting(credentialTypeDescription.name))?_c('div',{class:_vm.$style.edit},[_c('font-awesome-icon',{staticClass:"clickable",attrs:{"icon":"pen","title":_vm.$locale.baseText('nodeCredentials.updateCredential')},on:{"click":function($event){return _vm.editCredential(credentialTypeDescription.name)}}})],1):_vm._e()],1)])],1)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }