import { IRestApiContext } from '@/Interface';
import { makeRestApiRequest } from './helpers';

export async function getNewWorkflow(context: IRestApiContext, name?: string) {
	return await makeRestApiRequest(context, 'GET', `/workflows/new`, name ? { name } : {});
}


export async function getNewEmailadress(context: IRestApiContext, data:  {workflowId: string, environment: string, webhookUrl: string}) {
	return await makeRestApiRequest(context, 'POST', `/generate-email`, data);
}

