
//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import {
	GenericValue,
	IDataObject,
	INodeExecutionData,
	INodeTypeDescription,
	IRunData,
	IRunExecutionData,
	ITaskData,
	IWorkflowDataProxyAdditionalKeys,
	NodeParameterValue,
	INodeParameters,
	INodeProperties,
	NodeHelpers,
	Workflow,
	WorkflowDataProxy,
} from 'n8n-workflow';

import {
	IBinaryDisplayData,
	IExecutionResponse,
	INodeUi,
	ITableData,
	IUpdateInformation,
	IVariableSelectorOption,
} from '@/Interface';

import {
	MAX_DISPLAY_DATA_SIZE,
	MAX_DISPLAY_ITEMS_AUTO_ALL,
	PLACEHOLDER_FILLED_AT_EXECUTION_TIME,
} from '@/constants';

import BinaryDataDisplay from '@/components/BinaryDataDisplay.vue';
import NodeErrorView from '@/components/Error/NodeErrorView.vue';

import { copyPaste } from '@/components/mixins/copyPaste';
import { externalHooks } from "@/components/mixins/externalHooks";
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowRun } from '@/components/mixins/workflowRun';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';

// import { saveAs } from 'file-saver';
// @ts-ignore
import findDeep from 'deepdash/findDeep';
import { get, set, unset } from 'lodash';
import MapTree from './MapTree.vue';

import { ready, newInstance, BrowserJsPlumbInstance } from "@jsplumb/browser-ui";
import { BezierConnector } from '@jsplumb/connector-bezier';
import Vue from 'vue';
import { AnyMxRecord } from 'dns';

// A path that does not exist so that nothing is selected by default
const deselectedPlaceholder = '_!^&*';

export default mixins(
	copyPaste,
	externalHooks,
	genericHelpers,
	nodeHelpers,
	workflowHelpers,
	workflowRun,
)
	.extend({
		name: 'MappingData',
		components: {
			BinaryDataDisplay,
			NodeErrorView,
			VueJsonPretty,
			MapTree,
		},
		data() {
			return {
				binaryDataPreviewActive: false,
				dataSize: 0,
				deselectedPlaceholder,
				displayMode: "Mapping",
				state: {
					value: '' as object | number | string,
					path: deselectedPlaceholder,
				},
				runIndex: 0,
				showData: false,
				outputIndex: 0,
				maxDisplayItems: 25 as number | null,
				binaryDataDisplayVisible: false,
				binaryDataDisplayData: null as IBinaryDisplayData | null,
				MAX_DISPLAY_DATA_SIZE,
				MAX_DISPLAY_ITEMS_AUTO_ALL,
				treeInputData: [] as any,
				treeOutputData: [] as any,
				jsPlumbInstance: null as null | BrowserJsPlumbInstance,
				nodesLists: [] as any,
			};
		},
		created() {
			this.init();

		},
		mounted() {
			// this.initMapper();
			window.addEventListener("resize", this.onWindowResize);
			this.nodesLists = document.querySelectorAll(".base-list");
			if (this.nodesLists.length > 0)
				this.nodesLists.forEach((node: any) => {
					node.addEventListener("scroll", this.onListScroll);
				});
		},
		beforeDestroy: function () {
			this.nodesLists.forEach((node: any) => {
				node.removeEventListener("scroll", this.onListScroll);
			});
		},
		computed: {
			nodeParams(): any {
				return this.getNodeParameters(this.node!.name, `$node["${this.node!.name}"].parameter`, undefined);
			},
			currentMapperNode(): any {
				return this.$store.state.mapperNode[this.node!.name];
			},
			inputTreeData(): any[] {
				if (!this.node) return [];
				const currentName = this.node!.name;
				const jsonMappingParams = this.getNodeParameters(this.node!.name, `$node["${currentName}"].parameter`, undefined);
				// const mapperNodes = this.$store.getters.getMapperNode(currentName);
				const mapperNodes = {
					inputs: [] as any,
					outputs: [] as any,
				};
				//@ts-ignore
				mapperNodes.inputs = jsonMappingParams[0].value.split(',');
				const input = this.getFilterResults('', 0);

				// findDeep
				const inputTreeData = mapperNodes.inputs.map((v: any) => {
					const found = findDeep(input, { name: v }, { childrenPath: 'options' });
					return found ? found.value : null;
				});

				return inputTreeData ? inputTreeData : [];
			},
			outputTreeData(): any[] {
				if (!this.node) return [];
				const currentName = this.node!.name;

				const jsonMappingParams = this.getNodeParameters(this.node!.name, `$node["${currentName}"].parameter`, undefined);
				// const mapperNodes = this.$store.getters.getMapperNode(currentName);
				const mapperNodes = {
					inputs: [] as any,
					outputs: [] as any,
				};
				//@ts-ignore
				mapperNodes.outputs = jsonMappingParams[1].value.split(',');

				const output = this.getFilterResults('', 0);

				const outputTreeData = mapperNodes.outputs.map((v: any) => {
					const found = findDeep(output, { name: v }, { childrenPath: 'options' });
					return found ? found.value : null;
				});

				// findDeep
				return outputTreeData ? outputTreeData : [];
			},
			hasInputAndOutput(): boolean {
				return this.inputTreeData.length > 0 && this.outputTreeData.length > 0;
			},
			hasNodeRun(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData.hasOwnProperty(this.node.name));
			},
			hasRunError(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData[this.node.name] && this.workflowRunData[this.node.name][this.runIndex] && this.workflowRunData[this.node.name][this.runIndex].error);
			},
			workflow(): Workflow {
				return this.getWorkflow();
			},
			workflowRunning(): boolean {
				return this.$store.getters.isActionActive('workflowRunning');
			},
			workflowExecution(): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowRunData(): IRunData | null {
				if (this.workflowExecution === null) {
					return null;
				}
				const executionData: IRunExecutionData = this.workflowExecution.data;
				return executionData.resultData.runData;
			},
			maxDisplayItemsOptions(): number[] {
				const options = [25, 50, 100, 250, 500, 1000].filter(option => option <= this.dataCount);
				if (!options.includes(this.dataCount)) {
					options.push(this.dataCount);
				}
				return options;
			},
			node(): INodeUi | null {
				return this.$store.getters.activeNode;
			},
			runMetadata() {
				if (!this.node || this.workflowExecution === null) {
					return null;
				}

				const runData = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return null;
				}

				if (runData[this.node.name].length <= this.runIndex) {
					return null;
				}

				const taskData: ITaskData = runData[this.node.name][this.runIndex];
				return {
					executionTime: taskData.executionTime,
					startTime: new Date(taskData.startTime).toLocaleString(),
				};
			},
			dataCount(): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length <= this.runIndex) {
					return 0;
				}

				if (runData[this.node.name][this.runIndex].hasOwnProperty('error')) {
					return 1;
				}

				if (!runData[this.node.name][this.runIndex].hasOwnProperty('data') ||
					runData[this.node.name][this.runIndex].data === undefined
				) {
					return 0;
				}

				const inputData = this.getMainInputData(runData[this.node.name][this.runIndex].data!, this.outputIndex);

				return inputData.length;
			},
			maxOutputIndex(): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length < this.runIndex) {
					return 0;
				}

				if (runData[this.node.name][this.runIndex].data === undefined ||
					runData[this.node.name][this.runIndex].data!.main === undefined
				) {
					return 0;
				}

				return runData[this.node.name][this.runIndex].data!.main.length - 1;
			},
			maxRunIndex(): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length) {
					return runData[this.node.name].length - 1;
				}

				return 0;
			},
			jsonData(): IDataObject[] {
				let inputData = this.getNodeInputData(this.node, this.runIndex, this.outputIndex);
				if (inputData.length === 0 || !Array.isArray(inputData)) {
					return [];
				}

				if (this.maxDisplayItems !== null) {
					inputData = inputData.slice(0, this.maxDisplayItems);
				}

				return this.convertToJson(inputData);
			},
			inputData(): any[] {

				return [];
			},
		},
		methods: {
			checkAndDeleteConnections() {
				let connections = this.jsPlumbInstance!.getConnections() as any[];
				connections.map((cnx: any) => {
					// if (cnx.source && cnx.target) {
					const sourceNode = cnx.source.getAttribute("datapath").split(".")[0];
					const targetNode = cnx.target.getAttribute("datapath").split(".")[0];

					if ( this.inputTreeData[0] === null || !this.inputTreeData.find((node: any) => node.name === sourceNode)) {
						this.jsPlumbInstance!.deleteConnection(cnx);
						this.jsPlumbInstance!.repaintEverything();
					};
					if ( this.outputTreeData[0] === null || !this.outputTreeData.find((node: any) => node.name === targetNode)) {
						this.jsPlumbInstance!.deleteConnection(cnx);
						this.jsPlumbInstance!.repaintEverything();
					};
					// }
				});
			},
			collapseChildren(children: any, path: any) {

				let endpoint: HTMLElement | null;
				let labelTag: HTMLElement | null;

				if (children != null && children.length > 0) {
					for (let i = 0; i < children.length; i++) {
						endpoint = document.querySelector(`.drag-endpoint[keyselector="${children[i].name}"][datapath="${path}"]`);
						labelTag = document.querySelector(`.label-text[keyselector="${children[i].name}"][datapath="${path}"]`);

						if (endpoint != null) {
							endpoint.style.marginTop = "-56px";

							let elementLabel: HTMLElement | null = endpoint.previousElementSibling as HTMLElement;
							if (elementLabel != null)
								elementLabel.style.visibility = "hidden";
							elementLabel.style.height = '0';
							let toggler = elementLabel.previousElementSibling as HTMLElement;
							if (toggler != null) {
								toggler.style.visibility = "hidden";
								toggler.style.height = '0';
							}

							let parent = endpoint.closest(".tree-border") as HTMLElement;
							if (parent != null) {
								parent.style.height = '0';
							}
							let li = endpoint.closest("#groupWrapper") as HTMLElement;
							if (li != null) {
								li.style.height = '0';
								li.style.padding = '0';
							}

						} else if (endpoint === null && labelTag != null) {
							labelTag.style.visibility = "hidden";
							labelTag.style.height = "0";
							let toggler = labelTag.previousElementSibling as HTMLElement;
							if (toggler != null) {
								toggler.style.visibility = "hidden";
								toggler.style.height = '0';
							}
							let parent = labelTag.closest(".tree-border") as HTMLElement;
							if (parent != null) {
								parent.style.height = '0';
							}
							let li = labelTag.closest("#groupWrapper") as HTMLElement;
							if (li != null) {
								li.style.height = '0';
								li.style.padding = '0';
							}
						}
						if (children[i].options) {
							this.collapseChildren(children[i].options, path + "." + children[i].name);
						}
					}
				}
				if (this.jsPlumbInstance != null)
					this.jsPlumbInstance.repaintEverything();
			},
			unCollapseChildren(children: any, path: any) {
				let endpoint: HTMLElement | null;
				let labelTag: HTMLElement | null;

				if (children != null && children.length > 0) {
					for (let i = 0; i < children.length; i++) {
						endpoint = document.querySelector(`.drag-endpoint[keyselector="${children[i].name}"][datapath="${path}"]`);
						labelTag = document.querySelector(`.label-text[keyselector="${children[i].name}"][datapath="${path}"]`);

						if (endpoint != null) {
							endpoint.style.marginTop = "unset";
							let elementLabel = endpoint.previousElementSibling as HTMLElement;
							if (elementLabel != null) {
								let toggler = elementLabel.previousElementSibling as HTMLElement;
								elementLabel.style.visibility = "unset";
								elementLabel.style.height = 'unset';

								if (toggler != null) {
									toggler.style.visibility = "unset";
									toggler.style.height = '0';
								}
							}

							let parent = endpoint.closest(".tree-border") as HTMLElement;
							if (parent != null) {
								parent.style.height = "unset";
							}
							let li = endpoint.closest("#groupWrapper") as HTMLElement;
							if (li != null) {
								li.style.height = "unset";
								li.style.padding = "0.7rem 0";
							}
						} else if (labelTag != null) {
							labelTag.style.visibility = "unset";
							labelTag.style.height = "unset";

							let toggler = labelTag.previousElementSibling as HTMLElement;
							if (toggler != null) {
								toggler.style.visibility = "unset";
								toggler.style.height = "unset";

							}
							let parent = labelTag.closest(".tree-border") as HTMLElement;
							if (parent != null) {
								parent.style.height = "unset";
							}
							let li = labelTag.closest("#groupWrapper") as HTMLElement;
							if (li != null) {
								li.style.height = "unset";
								li.style.padding = "0.7rem 0";
							}
						}


						if (children[i].options) {
							this.unCollapseChildren(children[i].options, path + "." + children[i].name);
						}
					};
				}
				if (this.jsPlumbInstance != null)
					this.jsPlumbInstance.repaintEverything();
			},

			getNodeByPath(treeData: any, path: any) {

				const pathArr = path.split(".");
				let currentNode = treeData;

				if (pathArr[0] === '') {
					pathArr.shift();
				}

				const parent = currentNode.find((n: any) => n.name === pathArr[0]);

				if (pathArr.length === 1 && parent.options) {
					return parent.options;
				}

				if (!parent) {
					return null;
				}

				for (let i = 0; i < pathArr.length; i++) {
					const label = pathArr[i];

					if (parent.options) {
						currentNode = parent.options.find((n: any) => n.name === label);
					}
					// this.getNodeByPath(parent, pathArr.shift());
				}

				return currentNode.options;
			},
			handleCollapse(payload: any, tree: any) {
				let children = this.getNodeByPath(tree, payload.clickedItemPath);

				if (payload.clickedItemPath[0] == ".") {
					payload.clickedItemPath = payload.clickedItemPath.substring(1);
				}
				if (!payload.collapsed) {
					this.collapseChildren(children, payload.clickedItemPath);
				} else {
					this.unCollapseChildren(children, payload.clickedItemPath);
				}
			},


			init() {
				// Reset the selected output index every time another node gets selected
				this.outputIndex = 0;
				this.maxDisplayItems = 25;
				this.refreshDataSize();
				setTimeout(() => {
					// if (this.hasInputAndOutput)
					this.initMapper();
				}, 200);

			},
			initMapper() {
				ready(() => {
					const canvas = document.getElementById("canvas");
					const instance = newInstance({
						connector: BezierConnector.type,
						paintStyle: { strokeWidth: 3, stroke: "#9E9E9E" },
						endpoint: { type: "Dot", options: { radius: 5 } },
						endpointStyle: { stroke: "#eee", fill: "#9E9E9E", strokeWidth: 1 },
						container: canvas as any,
						listStyle: {
							endpoint: { type: "Dot", options: { radius: 8 } },
						},
					});

					// get the two elements that contain a list inside them
					const list1El = document.querySelector("#list-one") as Element;
					const list2El = document.querySelector("#list-two") as Element;


					instance.manage(list1El);
					instance.manage(list2El);

					// stop the elements from being draggable
					instance.setDraggable(list1El, false);
					instance.setDraggable(list2El, false);

					instance.registerConnectionType("link", {
						anchors: [
							["Center", "Center"],
							["Center", "Center"],
						],
					});

					// suspend drawing and initialise.
					instance.batch(() => {
						// add all list elements to the list of elements managed by the instance
						instance.manageAll(document.querySelectorAll(".list ul .drag-endpoint"));
						// register a selector for drag
						instance.addSourceSelector("[source] .drag-endpoint", {
							allowLoopback: false,
							edgeType: "link",
						});
						// and a selector for drop
						instance.addTargetSelector("[target] .drag-endpoint", {
							anchor: ["Center", "Center"],
						});

						const nodeName = this.node!.name;
						const jsonMappingParams = this.getNodeParameters(this.node!.name, `$node["${nodeName}"].parameter`, undefined);

						if (jsonMappingParams) {
							const mappingParams = jsonMappingParams![2];
							if (mappingParams.value && mappingParams.value !== "") {
								const mapping: any[] = JSON.parse(mappingParams.value);
								for (const cnx of mapping) {
									const source = document.querySelector(`[source] .drag-endpoint[datakey='${cnx.source.key}'][datapath="${cnx.source.path}"]`) as Element;
									const target = document.querySelector(`[target] .drag-endpoint[datakey=\'${cnx.target.key}\'][datapath="${cnx.target.path}"]`) as Element;
									if (source && target) {
										instance.connect({
											source,
											target,
											type: "link",
										});
									}
								}
							}
						}
					});

					instance.bind("connection", () => {
						this.getConnections();
					});

					/* instance.bind("connection:move", () => {
						this.getConnections();
					}); */

					instance.bind("connection:detach", () => {
						setTimeout(() => {
							this.getConnections();
						}, 200);
					});

					// console.log('plumb inited');

					this.jsPlumbInstance = instance;
					this.onListScroll();
					this.nodesLists = document.querySelectorAll(".base-list");
					this.nodesLists.forEach((list: any) => {
						list.addEventListener("scroll", this.onListScroll);
					});

				});
			},
			onWindowResize() {
				if (this.jsPlumbInstance != null)
					this.jsPlumbInstance.repaintEverything();
			},
			onListScroll() {
				if (this.jsPlumbInstance != null)
					this.jsPlumbInstance.repaintEverything();
			},
			getConnections() {
				let connections = this.jsPlumbInstance!.getConnections() as any[];
				connections = connections.map((cnx: any) => {
					if (cnx.source && cnx.target) {
						const source = {
							key: `${cnx.source.getAttribute("datakey")}`,
							path: `${cnx.source.getAttribute("datapath")}`,
						};
						const target = {
							key: `${cnx.target.getAttribute("datakey")}`,
							path: `${cnx.target.getAttribute("datapath")}`,
						};
						return { source, target };
					}
					return { source: null, target: null };
				});
				if (connections.length !== 0 && connections[0].source !== null) {
					const parameterDataMapping = {
						name: `parameters.jsonMapping`,
						node: this.$store.getters.activeNode.name,
						value: JSON.stringify(connections),
					};
					this.$emit('mappingChanged', parameterDataMapping);

					// update other nodes
					connections.forEach((io: any) => {
						const nodeName = io.target.path.split('.')[0];
						const node = this.$store.getters.getNodeByName(nodeName);

						const paramToEdit = JSON.parse(io.target.key.match(/\["(.*?)"\]/g)[1])[0];
						node.parameters[paramToEdit] = `={{${io.source.key}}}`;

					});

				}
				else {
					const parameterDataMapping = {
						name: `parameters.jsonMapping`,
						node: this.$store.getters.activeNode.name,
						value: '[]',
					};

					this.$emit('mappingChanged', parameterDataMapping);
				}
			},

			valueChanged(parameterData: IUpdateInformation) {
				let newValue: NodeParameterValue = parameterData.value;

				// Save the node name before we commit the change because
				// we need the old name to rename the node properly
				const nodeNameBefore = parameterData.node;
				const node = this.$store.getters.getNodeByName(nodeNameBefore);
				if (parameterData.name === 'name') {
					// Name of node changed so we have to set also the new node name as active

					// Update happens in NodeView so emit event
					const sendData = {
						value: newValue,
						oldValue: nodeNameBefore,
						name: parameterData.name,
					};
					this.$emit('valueChanged', sendData);

					this.$store.commit('setActiveNode', newValue);
				} else if (parameterData.name.startsWith('parameters.')) {
					// A node parameter changed

					const nodeType = this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;
					if (!nodeType) {
						return;
					}

					// Get only the parameters which are different to the defaults
					let nodeParameters = NodeHelpers.getNodeParameters(nodeType.properties, node.parameters, false, false);
					const oldNodeParameters = Object.assign({}, nodeParameters);

					// Copy the data because it is the data of vuex so make sure that
					// we do not edit it directly
					nodeParameters = JSON.parse(JSON.stringify(nodeParameters));

					// Remove the 'parameters.' from the beginning to just have the
					// actual parameter name
					const parameterPath = parameterData.name.split('.').slice(1).join('.');

					// Check if the path is supposed to change an array and if so get
					// the needed data like path and index
					const parameterPathArray = parameterPath.match(/(.*)\[(\d+)\]$/);

					// Apply the new value
					if (parameterData.value === undefined && parameterPathArray !== null) {
						// Delete array item
						const path = parameterPathArray[1];
						const index = parameterPathArray[2];
						const data = get(nodeParameters, path);

						if (Array.isArray(data)) {
							data.splice(parseInt(index, 10), 1);
							Vue.set(nodeParameters as object, path, data);
						}
					} else {
						if (newValue === undefined) {
							unset(nodeParameters as object, parameterPath);
						} else {
							set(nodeParameters as object, parameterPath, newValue);
						}
					}

					// Get the parameters with the now new defaults according to the
					// from the user actually defined parameters
					nodeParameters = NodeHelpers.getNodeParameters(nodeType.properties, nodeParameters as INodeParameters, true, false);

					// Update the data in vuex
					const updateInformation = {
						name: node.name,
						value: nodeParameters,
					};

					this.$store.commit('setNodeParameters', updateInformation);

					// this.$externalHooks().run('nodeSettings.valueChanged', { parameterPath, newValue, parameters: this.parameters, oldNodeParameters });

					this.updateNodeParameterIssues(node, nodeType);
					this.updateNodeCredentialIssues(node);
				} else {

					// Update data in vuex
					const updateInformation = {
						name: node.name,
						key: parameterData.name,
						value: newValue,
					};
					this.$store.commit('setNodeValue', updateInformation);
				}
			},
			removeEmptyEntries(inputData: IVariableSelectorOption[] | IVariableSelectorOption | null): IVariableSelectorOption[] | IVariableSelectorOption | null {
				if (Array.isArray(inputData)) {
					const newItems: IVariableSelectorOption[] = [];
					let tempItem: IVariableSelectorOption;
					inputData.forEach((item) => {
						tempItem = this.removeEmptyEntries(item) as IVariableSelectorOption;
						if (tempItem !== null) {
							newItems.push(tempItem);
						}
					});
					return newItems;
				}

				if (inputData && inputData.options) {
					const newOptions = this.removeEmptyEntries(inputData.options);
					if (Array.isArray(newOptions) && newOptions.length) {
						// Has still options left so return
						inputData.options = newOptions;
						return inputData;
					} else if (Array.isArray(newOptions) && newOptions.length === 0) {
						delete inputData.options;
						return inputData;
					}
					// Has no options left so remove
					return null;
				} else {
					// Is an item no category
					return inputData;
				}
			},
			sortOptions(options: IVariableSelectorOption[] | null): IVariableSelectorOption[] | null {
				if (options === null) {
					return null;
				}
				return options.sort((a: IVariableSelectorOption, b: IVariableSelectorOption) => {
					const aHasOptions = a.hasOwnProperty('options');
					const bHasOptions = b.hasOwnProperty('options');

					if (bHasOptions && !aHasOptions) {
						// When b has options but a not list it first
						return 1;
					} else if (!bHasOptions && aHasOptions) {
						// When a has options but b not list it first
						return -1;
					}

					// Else simply sort alphabetically
					if (a.name < b.name) { return -1; }
					if (a.name > b.name) { return 1; }
					return 0;
				});
			},
			getNodeContext(workflow: Workflow, runExecutionData: IRunExecutionData | null, parentNode: string[], nodeName: string, filterText: string): IVariableSelectorOption[] | null {
				const inputIndex = 0;
				const itemIndex = 0;
				const inputName = 'main';
				const runIndex = 0;
				const returnData: IVariableSelectorOption[] = [];

				const connectionInputData = this.connectionInputData(parentNode, inputName, runIndex, inputIndex);

				if (connectionInputData === null) {
					return returnData;
				}

				const additionalKeys: IWorkflowDataProxyAdditionalKeys = {
					$executionId: PLACEHOLDER_FILLED_AT_EXECUTION_TIME,
					$resumeWebhookUrl: PLACEHOLDER_FILLED_AT_EXECUTION_TIME,
				};

				const dataProxy = new WorkflowDataProxy(workflow, runExecutionData, runIndex, itemIndex, nodeName, connectionInputData, {}, 'manual', additionalKeys);
				const proxy = dataProxy.getDataProxy();

				// @ts-ignore
				const nodeContext = proxy.$node[nodeName].context as IContextObject;
				for (const key of Object.keys(nodeContext)) {
					if (filterText !== undefined && key.toLowerCase().indexOf(filterText) === -1) {
						// If filter is set apply it
						continue;
					}

					returnData.push({
						name: key,
						key: `$node["${nodeName}"].context["${key}"]`,
						// @ts-ignore
						value: nodeContext[key],
					});
				}

				return returnData;
			},

			getPathNormalized(path: string | undefined): string {
				if (path === undefined) {
					return '';
				}
				const pathArray = path.split('.');

				const finalArray = [];
				let item: string;
				for (const pathPart of pathArray) {
					const pathParts = pathPart.match(/\[.*?\]/g);
					if (pathParts === null) {
						// Does not have any brakets so add as it is
						finalArray.push(pathPart);
					} else {
						// Has brakets so clean up the items and add them
						if (pathPart.charAt(0) !== '[') {
							// Does not start with a braket so there is a part before
							// we have to add
							finalArray.push(pathPart.substr(0, pathPart.indexOf('[')));
						}

						for (item of pathParts) {
							item = item.slice(1, -1);
							if (['"', "'"].includes(item.charAt(0))) {
								// Is a string
								item = item.slice(1, -1);
								finalArray.push(item);
							} else {
								// Is a number
								finalArray.push(`[${item}]`);
							}
						}
					}
				}

				return finalArray.join('|');
			},

			jsonDataToFilterOption(inputData: IDataObject | GenericValue | IDataObject[] | GenericValue[] | null, parentPath: string, propertyName: string, filterText?: string, propertyIndex?: number, displayName?: string, skipKey?: string): IVariableSelectorOption[] {
				let fullpath = `${parentPath}["${propertyName}"]`;
				if (propertyIndex !== undefined) {
					fullpath += `[${propertyIndex}]`;
				}

				const returnData: IVariableSelectorOption[] = [];
				if (inputData === null) {
					returnData.push(
						{
							name: propertyName,
							key: fullpath,
							value: '[null]',
						} as IVariableSelectorOption,
					);
					return returnData;
				} else if (Array.isArray(inputData)) {
					let newPropertyName = propertyName;
					let newParentPath = parentPath;
					if (propertyIndex !== undefined) {
						newParentPath += `["${propertyName}"]`;
						newPropertyName = propertyIndex.toString();
					}

					const arrayData: IVariableSelectorOption[] = [];

					for (let i = 0; i < inputData.length; i++) {
						arrayData.push.apply(arrayData, this.jsonDataToFilterOption(inputData[i], newParentPath, newPropertyName, filterText, i, `[Item: ${i}]`, skipKey));
					}

					returnData.push(
						{
							name: displayName || propertyName,
							options: arrayData,
							key: fullpath,
							allowParentSelect: true,
							dataType: 'array',
						} as IVariableSelectorOption,
					);
				} else if (typeof inputData === 'object') {
					const tempValue: IVariableSelectorOption[] = [];

					for (const key of Object.keys(inputData)) {
						tempValue.push.apply(tempValue, this.jsonDataToFilterOption((inputData as IDataObject)[key], fullpath, key, filterText, undefined, undefined, skipKey));
					}

					if (tempValue.length) {
						returnData.push(
							{
								name: displayName || propertyName,
								options: this.sortOptions(tempValue),
								key: fullpath,
								allowParentSelect: true,
								dataType: 'object',
							} as IVariableSelectorOption,
						);
					}
				} else {
					if (filterText !== undefined && propertyName.toLowerCase().indexOf(filterText) === -1) {
						return returnData;
					}

					// Skip is currently only needed for leafs so only check here
					if (this.getPathNormalized(skipKey) !== this.getPathNormalized(fullpath)) {
						returnData.push(
							{
								name: propertyName,
								key: fullpath,
								value: inputData,
							} as IVariableSelectorOption,
						);
					}
				}

				return returnData;
			},

			getNodeOutputData(runData: IRunData, nodeName: string, filterText: string, itemIndex = 0, runIndex = 0, inputName = 'main', outputIndex = 0, useShort = false): IVariableSelectorOption[] | null {
				if (!runData.hasOwnProperty(nodeName)) {
					// No data found for node
					return null;
				}

				if (runData[nodeName].length <= runIndex) {
					// No data for given runIndex
					return null;
				}

				if (!runData[nodeName][runIndex].hasOwnProperty('data') ||
					runData[nodeName][runIndex].data === null ||
					runData[nodeName][runIndex].data === undefined) {
					// Data property does not exist or is not set (even though it normally has to)
					return null;
				}

				if (!runData[nodeName][runIndex].data!.hasOwnProperty(inputName)) {
					// No data found for inputName
					return null;
				}

				if (runData[nodeName][runIndex].data![inputName].length <= outputIndex) {
					// No data found for output Index
					return null;
				}

				// The data should be identical no matter to which node it gets so always select the first one
				if (runData[nodeName][runIndex].data![inputName][outputIndex] === null ||
					runData[nodeName][runIndex].data![inputName][outputIndex]!.length <= itemIndex) {
					// No data found for node connection found
					return null;
				}

				const outputData = runData[nodeName][runIndex].data![inputName][outputIndex]![itemIndex];

				const returnData: IVariableSelectorOption[] = [];

				// Get json data
				if (outputData.hasOwnProperty('json')) {

					const jsonPropertyPrefix = useShort === true ? '$json' : `$node["${nodeName}"].json`;

					const jsonDataOptions: IVariableSelectorOption[] = [];
					for (const propertyName of Object.keys(outputData.json)) {
						jsonDataOptions.push.apply(jsonDataOptions, this.jsonDataToFilterOption(outputData.json[propertyName], jsonPropertyPrefix, propertyName, filterText));
					}

					if (jsonDataOptions.length) {
						returnData.push(
							{
								name: 'JSON',
								options: this.sortOptions(jsonDataOptions),
							},
						);
					}
				}

				return returnData;
			},
			getNodeParameters(nodeName: string, path: string, skipParameter?: string, filterText?: string): IVariableSelectorOption[] | null {
				const node = this.workflow.getNode(nodeName);
				if (node === null) {
					return null;
				}

				const returnParameters: IVariableSelectorOption[] = [];
				for (const parameterName in node.parameters) {
					if (parameterName === skipParameter) {
						// Skip the parameter
						continue;
					}

					if (filterText !== undefined && parameterName.toLowerCase().indexOf(filterText) === -1) {
						// If filter is set apply it
						continue;
					}

					returnParameters.push.apply(returnParameters, this.jsonDataToFilterOption(node.parameters[parameterName], path, parameterName, filterText, undefined, undefined, skipParameter));
				}

				return returnParameters;
			},
			getFilterResults(filterText: string = '', itemIndex: number): IVariableSelectorOption[] {
				const inputName = 'main';

				const activeNode: INodeUi | null = this.$store.getters.activeNode;

				if (activeNode === null) {
					return [];
				}

				const executionData = this.$store.getters.getWorkflowExecution as IExecutionResponse | null;
				let parentNode = this.workflow.getParentNodes(activeNode.name, inputName, 1);
				let runData = this.$store.getters.getWorkflowRunData as IRunData | null;

				if (runData === null) {
					runData = {};
				}

				let returnData: IVariableSelectorOption[] | null = [];
				// -----------------------------------------
				// Add the parameters of the current node
				// -----------------------------------------

				// Add the parameters
				const currentNodeData: IVariableSelectorOption[] = [];

				let tempOptions: IVariableSelectorOption[];
				if (executionData !== null) {
					const runExecutionData: IRunExecutionData = executionData.data;

					tempOptions = this.getNodeContext(this.workflow, runExecutionData, parentNode, activeNode.name, filterText) as IVariableSelectorOption[];
					if (tempOptions.length) {
						currentNodeData.push(
							{
								name: 'Context',
								options: this.sortOptions(tempOptions),
							} as IVariableSelectorOption,
						);
					}
				}

				let tempOutputData;

				if (parentNode.length) {
					// If the node has an input node add the input data

					// Check from which output to read the data.
					// Depends on how the nodes are connected.
					// (example "IF" node. If node is connected to "true" or to "false" output)
					const outputIndex = this.workflow.getNodeConnectionOutputIndex(activeNode.name, parentNode[0], 'main');

					tempOutputData = this.getNodeOutputData(runData, parentNode[0], filterText, itemIndex, 0, 'main', outputIndex, true) as IVariableSelectorOption[];

					if (tempOutputData) {
						if (JSON.stringify(tempOutputData).length < 102400) {
							// Data is reasonable small (< 100kb) so add it
							currentNodeData.push(
								{
									name: 'Input Data',
									options: this.sortOptions(tempOutputData),
								},
							);
						} else {
							// Data is to large so do not add
							currentNodeData.push(
								{
									name: 'Input Data',
									options: [
										{
											name: '[Data to large]',
										},
									],
								},
							);
						}
					}
				}

				const initialPath = '$parameter';
				let skipParameter = '';
				if (skipParameter.startsWith('parameters.')) {
					skipParameter = initialPath + skipParameter.substring(10);
				}

				currentNodeData.push(
					{
						name: this.$locale.baseText('variableSelector.parameters'),
						options: this.sortOptions(this.getNodeParameters(activeNode.name, initialPath, skipParameter, filterText) as IVariableSelectorOption[]),
					},
				);

				returnData.push(
					{
						name: this.$locale.baseText('variableSelector.currentNode'),
						options: this.sortOptions(currentNodeData),
					},
				);

				// Add the input data

				// -----------------------------------------
				// Add all the nodes and their data
				// -----------------------------------------
				const allNodesData: IVariableSelectorOption[] = [];
				let nodeOptions: IVariableSelectorOption[];
				const upstreamNodes = this.workflow.getParentNodes(activeNode.name, inputName);

				const workflowNodes = Object.entries(this.workflow.nodes);

				// Sort the nodes according to their position relative to the current node
				workflowNodes.sort((a, b) => {
					return upstreamNodes.indexOf(b[0]) - upstreamNodes.indexOf(a[0]);
				});

				for (const [nodeName, node] of workflowNodes) {
					// Add the parameters of all nodes
					// TODO: Later have to make sure that no parameters can be referenced which have expression which use input-data (for nodes which are not parent nodes)

					if (nodeName === activeNode.name) {
						// Skip the current node as this one get added separately
						continue;
					}

					nodeOptions = [
						{
							name: this.$locale.baseText('variableSelector.parameters'),
							options: this.sortOptions(this.getNodeParameters(nodeName, `$node["${nodeName}"].parameter`, undefined, filterText)),
						} as IVariableSelectorOption,
					];

					if (executionData !== null) {
						const runExecutionData: IRunExecutionData = executionData.data;

						parentNode = this.workflow.getParentNodes(nodeName, inputName, 1);
						tempOptions = this.getNodeContext(this.workflow, runExecutionData, parentNode, nodeName, filterText) as IVariableSelectorOption[];
						if (tempOptions.length) {
							nodeOptions = [
								{
									name: this.$locale.baseText('variableSelector.context'),
									options: this.sortOptions(tempOptions),
								} as IVariableSelectorOption,
							];
						}
					}

					if (upstreamNodes.includes(nodeName)) {
						// If the node is an upstream node add also the output data which can be referenced
						tempOutputData = this.getNodeOutputData(runData, nodeName, filterText, itemIndex);
						if (tempOutputData) {
							nodeOptions.push(
								{
									name: this.$locale.baseText('variableSelector.outputData'),
									options: this.sortOptions(tempOutputData),
								} as IVariableSelectorOption,
							);
						}
					}

					const shortNodeType = this.$locale.shortNodeType(node.type);

					allNodesData.push(
						{
							name: this.$locale.headerText({
								key: `headers.${shortNodeType}.displayName`,
								fallback: nodeName,
							}),
							options: this.sortOptions(nodeOptions),
						},
					);
				}

				returnData.push(
					{
						name: this.$locale.baseText('variableSelector.nodes'),
						options: allNodesData,
					},
				);

				// Remove empty entries and return
				returnData = this.removeEmptyEntries(returnData) as IVariableSelectorOption[] | null;

				if (returnData === null) {
					return [];
				}

				return returnData;
			},
			convertToJson(inputData: INodeExecutionData[]): IDataObject[] {
				const returnData: IDataObject[] = [];
				inputData.forEach((data) => {
					if (!data.hasOwnProperty('json')) {
						return;
					}
					returnData.push(data.json);
				});

				return returnData;
			},
			clearExecutionData() {
				this.$store.commit('setWorkflowExecutionData', null);
				this.updateNodesExecutionIssues();
			},
			dataItemClicked(path: string, data: object | number | string) {
				this.state.value = data;
			},
			getOutputName(outputIndex: number) {
				if (this.node === null) {
					return outputIndex + 1;
				}

				const nodeType = this.$store.getters.nodeType(this.node.type, this.node.typeVersion) as INodeTypeDescription | null;
				if (!nodeType || !nodeType.outputNames || nodeType.outputNames.length <= outputIndex) {
					return outputIndex + 1;
				}

				return nodeType.outputNames[outputIndex];
			},
			convertPath(path: string): string {
				// TODO: That can for sure be done fancier but for now it works
				const placeholder = '*___~#^#~___*';
				let inBrackets = path.match(/\[(.*?)\]/g);

				if (inBrackets === null) {
					inBrackets = [];
				} else {
					inBrackets = inBrackets.map(item => item.slice(1, -1)).map(item => {
						if (item.startsWith('"') && item.endsWith('"')) {
							return item.slice(1, -1);
						}
						return item;
					});
				}
				const withoutBrackets = path.replace(/\[(.*?)\]/g, placeholder);
				const pathParts = withoutBrackets.split('.');
				const allParts = [] as string[];
				pathParts.forEach(part => {
					let index = part.indexOf(placeholder);
					while (index !== -1) {
						if (index === 0) {
							allParts.push(inBrackets!.shift() as string);
							part = part.substr(placeholder.length);
						} else {
							allParts.push(part.substr(0, index));
							part = part.substr(index);
						}
						index = part.indexOf(placeholder);
					}
					if (part !== '') {
						allParts.push(part);
					}
				});

				return '["' + allParts.join('"]["') + '"]';
			},
			handleCopyClick(commandData: { command: string }) {
				const newPath = this.convertPath(this.state.path);

				let value: string;
				if (commandData.command === 'value') {
					if (typeof this.state.value === 'object') {
						value = JSON.stringify(this.state.value, null, 2);
					} else {
						value = this.state.value.toString();
					}
				} else {
					let startPath = '';
					let path = '';
					if (commandData.command === 'itemPath') {
						const pathParts = newPath.split(']');
						const index = pathParts[0].slice(1);
						path = pathParts.slice(1).join(']');
						startPath = `$item(${index}).$node["${this.node!.name}"].json`;
					} else if (commandData.command === 'parameterPath') {
						path = newPath.split(']').slice(1).join(']');
						startPath = `$node["${this.node!.name}"].json`;
					}
					if (!path.startsWith('[') && !path.startsWith('.') && path) {
						path += '.';
					}
					value = `{{ ${startPath + path} }}`;
				}

				this.copyToClipboard(value);
			},
			refreshDataSize() {
				// Hide by default the data from being displayed
				this.showData = false;

				// Check how much data there is to display
				const inputData = this.getNodeInputData(this.node, this.runIndex, this.outputIndex);

				const jsonItems = inputData.slice(0, this.maxDisplayItems || inputData.length).map(item => item.json);

				this.dataSize = JSON.stringify(jsonItems).length;

				if (this.dataSize < this.MAX_DISPLAY_DATA_SIZE) {
					// Data is reasonable small (< 200kb) so display it directly
					this.showData = true;
				}
			},
		},
		watch: {
			nodeParams(newVal) {
				this.checkAndDeleteConnections();
				if (this.jsPlumbInstance !== null) {
					this.jsPlumbInstance.batch(() => {
						// add all list elements to the list of elements managed by the instance
						//@ts-ignore
						this.jsPlumbInstance.manageAll(document.querySelectorAll(".list ul .drag-endpoint"));
						// register a selector for drag
						//@ts-ignore
						this.jsPlumbInstance.addSourceSelector("[source] .drag-endpoint", {
							allowLoopback: false,
							edgeType: "link",
						});
						// and a selector for drop
						//@ts-ignore
						this.jsPlumbInstance.addTargetSelector("[target] .drag-endpoint", {
							anchor: ["Center", "Center"],
						});
						//@ts-ignore
						this.jsPlumbInstance.bind("connection", () => {
							this.getConnections();
						});
						//@ts-ignore
						this.jsPlumbInstance.bind("connection:detach", () => {
							setTimeout(() => {
								this.getConnections();
							}, 200);
						});
					});
					this.nodesLists = document.querySelectorAll(".base-list");
					if (this.nodesLists.length > 0)
						this.nodesLists.forEach((node: any) => {
							node.addEventListener("scroll", this.onListScroll);
						});
				}
				if (this.jsPlumbInstance !== null) {
					this.jsPlumbInstance.repaintEverything();
				}
			},
			hasInputAndOutput(newVal) {
				if (newVal) {
					setTimeout(() => {
						// this.initMapper();
					}, 2000);
				}
				// else destroyMapper();
			},
			jsonData() {
				this.refreshDataSize();
			},
			displayMode(newValue, oldValue) {
				this.$externalHooks().run('runData.displayModeChanged', { newValue, oldValue });
			},
			maxRunIndex() {
				this.runIndex = Math.min(this.runIndex, this.maxRunIndex);
			},
			/* '$store.state.mapperNode' : { // watch mapping in the store
				handler(val, oldVal) {
					console.log(val);
					const mapperNode = this.$store.state.mapperNode[this.node!.name];

					const treeRes = this.getFilterResults('', 0);

					const outputTreeData = mapperNode.outputs.map((v: any) => {
						const found = findDeep(treeRes, {name: v}, {childrenPath: 'options'});
						return found ? found.value : null;
					});

					const inputTreeData = mapperNode.inputs.map((v: any) => {
						const found = findDeep(treeRes, {name: v}, {childrenPath: 'options'});
						return found ? found.value : null;
					});

					this.treeInputData = inputTreeData ? inputTreeData : [];
					this.treeOutputData = outputTreeData ? outputTreeData : [];
				},
				deep: true,
			}, */
		},
	});
