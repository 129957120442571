

import {
	INodeParameters,
	INodeProperties,
	NodeParameterValue,
} from 'n8n-workflow';

import { IUpdateInformation } from '@/Interface';

import MultipleParameter from '@/components/MultipleParameter.vue';
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import ParameterInputFull from '@/components/ParameterInputFull.vue';
import Calculator from '@/components/Calculator.vue';

import { get, set } from 'lodash';

import mixins from 'vue-typed-mixins';

export default mixins(
	genericHelpers,
	workflowHelpers,
)
	.extend({
		name: 'ParameterInputList',
		components: {
			MultipleParameter,
			ParameterInputFull,
			Calculator,
		},
		props: [
			'nodeValues', // INodeParameters
			'parameters', // INodeProperties
			'path', // string
			'hideDelete', // boolean
			'indent',
		],
		computed: {
			filteredParameters (): INodeProperties[] {
				return this.parameters.filter((parameter: INodeProperties) => this.displayNodeParameter(parameter));
			},
			filteredParameterNames (): string[] {
				return this.filteredParameters.map(parameter => parameter.name);
			},
		},
		methods: {
			multipleValues (parameter: INodeProperties): boolean {
				if (this.getArgument('multipleValues', parameter) === true) {
					return true;
				}
				return false;
			},
			getArgument (
				argumentName: string,
				parameter: INodeProperties,
			): string | string[] | number | boolean | undefined{
				if (parameter.typeOptions === undefined) {
					return undefined;
				}

				if (parameter.typeOptions[argumentName] === undefined) {
					return undefined;
				}

				return parameter.typeOptions[argumentName];
			},
			getPath (parameterName: string): string {
				return (this.path ? `${this.path}.` : '') + parameterName;
			},
			deleteOption (optionName: string): void {
				const parameterData = {
					name: this.getPath(optionName),
					value: undefined,
				};

				// TODO: If there is only one option it should delete the whole one

				this.$emit('valueChanged', parameterData);
			},
			displayNodeParameter (parameter: INodeProperties): boolean {
				if (parameter.type === 'hidden') {
					return false;
				}

				if (parameter.displayOptions === undefined) {
					// If it is not defined no need to do a proper check
					return true;
				}

				const nodeValues: INodeParameters = {};
				let rawValues = this.nodeValues;
				if (this.path) {
					rawValues = get(this.nodeValues, this.path);
				}

				// Resolve expressions
				const resolveKeys = Object.keys(rawValues);
				let key: string;
				let i = 0;
				let parameterGotResolved = false;
				do {
					key = resolveKeys.shift() as string;
					if (typeof rawValues[key] === 'string' && rawValues[key].charAt(0) === '=') {
						// Contains an expression that
						if (rawValues[key].includes('$parameter') && resolveKeys.some(parameterName => rawValues[key].includes(parameterName))) {
							// Contains probably an expression of a missing parameter so skip
							resolveKeys.push(key);
							continue;
						} else {
							// Contains probably no expression with a missing parameter so resolve
							try {
								nodeValues[key] = this.resolveExpression(rawValues[key], nodeValues) as NodeParameterValue;
							} catch (e) {
								// If expression is invalid ignore
								nodeValues[key] = '';
							}
							parameterGotResolved = true;
						}
					} else {
						// Does not contain an expression, add directly
						nodeValues[key] = rawValues[key];
					}
					// TODO: Think about how to calculate this best
					if (i++ > 50) {
						// Make sure we do not get caught
						break;
					}
				} while(resolveKeys.length !== 0);

				if (parameterGotResolved === true) {
					if (this.path) {
						rawValues = JSON.parse(JSON.stringify(this.nodeValues));
						set(rawValues, this.path, nodeValues);
						return this.displayParameter(rawValues, parameter, this.path);
					} else {
						return this.displayParameter(nodeValues, parameter, '');
					}
				}

				return this.displayParameter(this.nodeValues, parameter, this.path);
			},
			valueChanged (parameterData: IUpdateInformation): void {
				this.$emit('valueChanged', parameterData);
			},
		},
		watch: {
			filteredParameterNames(newValue, oldValue) {
				if (newValue === undefined) {
					return;
				}
				// After a parameter does not get displayed anymore make sure that its value gets removed
				// Is only needed for the edge-case when a parameter gets displayed depending on another field
				// which contains an expression.
				for (const parameter of oldValue) {
					if (!newValue.includes(parameter)) {
						const parameterData = {
							name: `${this.path}.${parameter}`,
							node: this.$store.getters.activeNode.name,
							value: undefined,
						};
						this.$emit('valueChanged', parameterData);
					}
				}
			},
		},
		beforeCreate: function () { // tslint:disable-line
		// Because we have a circular dependency on CollectionParameter import it here
		// to not break Vue.
		this.$options!.components!.FixedCollectionParameter = require('./FixedCollectionParameter.vue').default;
		this.$options!.components!.CollectionParameter = require('./CollectionParameter.vue').default;
		},
	});
