
import Vue from 'vue';
import camelcase from 'lodash.camelcase';

export default Vue.extend({
	props: ['item'],
	computed: {
		subcategoryName() {
			return camelcase(this.item.properties.subcategory);
		},
	},
});
