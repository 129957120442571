
import {
	IUpdateInformation,
} from '@/Interface';

import CollectionParameter from '@/components/CollectionParameter.vue';
import ParameterInput from '@/components/ParameterInput.vue';

import { get } from 'lodash';

import { genericHelpers } from '@/components/mixins/genericHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(genericHelpers)
	.extend({
		name: 'MultipleParameter',
		components: {
			CollectionParameter,
			ParameterInput,
		},
		props: [
			'nodeValues', // NodeParameters
			'parameter', // NodeProperties
			'path', // string
			'values', // NodeParameters[]
		],
		computed: {
			addButtonText (): string {
				if (
					!this.parameter.typeOptions ||
					(this.parameter.typeOptions && !this.parameter.typeOptions.multipleValueButtonText)
				) {
					return this.$locale.baseText('multipleParameter.addItem');
				}

				return this.$locale.nodeText().multipleValueButtonText(this.parameter);
			},
			hideDelete (): boolean {
				return this.parameter.options.length === 1;
			},
			sortable (): string {
				return this.parameter.typeOptions && this.parameter.typeOptions.sortable;
			},
		},
		methods: {
			addItem () {
				const name = this.getPath();
				let currentValue = get(this.nodeValues, name);

				if (currentValue === undefined) {
					currentValue = [];
				}

				currentValue.push(JSON.parse(JSON.stringify(this.parameter.default)));

				const parameterData = {
					name,
					value: currentValue,
				};

				this.$emit('valueChanged', parameterData);
			},
			deleteItem (index: number) {
				const parameterData = {
					name: this.getPath(index),
					value: undefined,
				};

				this.$emit('valueChanged', parameterData);
			},
			getPath (index?: number): string {
				return this.path + (index !== undefined ? `[${index}]` : '');
			},
			moveOptionDown (index: number) {
				this.values.splice(index + 1, 0, this.values.splice(index, 1)[0]);

				const parameterData = {
					name: this.path,
					value: this.values,
				};

				this.$emit('valueChanged', parameterData);
			},
			moveOptionUp (index: number) {
				this.values.splice(index - 1, 0, this.values.splice(index, 1)[0]);

				const parameterData = {
					name: this.path,
					value: this.values,
				};

				this.$emit('valueChanged', parameterData);
			},
			valueChanged (parameterData: IUpdateInformation) {
				this.$emit('valueChanged', parameterData);
			},
		},
	});
