
import mixins from "vue-typed-mixins";

import { IExecutionResponse } from "../../../Interface";

import { titleChange } from "@/components/mixins/titleChange";

import WorkflowNameShort from "@/components/WorkflowNameShort.vue";
import ReadOnly from "@/components/MainHeader/ExecutionDetails/ReadOnly.vue";

export default mixins(titleChange).extend({
	name: "ExecutionDetails",
	components: {
		WorkflowNameShort,
		ReadOnly,
	},
	computed: {
		executionId(): string | undefined {
			return this.$route.params.id;
		},
		executionFinished(): boolean {
			const fullExecution = this.$store.getters.getWorkflowExecution;

			return !!fullExecution && fullExecution.finished;
		},
		executionWaiting(): boolean {
			const fullExecution = this.$store.getters.getWorkflowExecution;

			return !!fullExecution && !!fullExecution.waitTill;
		},
		workflowExecution(): IExecutionResponse | null {
			return this.$store.getters.getWorkflowExecution;
		},
		workflowName(): string {
			return this.$store.getters.workflowName;
		},
	},
	methods: {
		async openWorkflow(workflowId: string) {
			this.$titleSet(this.workflowName, "IDLE");
			// Change to other workflow
			this.$router.push({
				name: "NodeViewExisting",
				params: { name: workflowId },
			});
		},
	},
});
