var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"node-view-root"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:tap",value:(_vm.touchTap),expression:"touchTap",arg:"tap"}],staticClass:"node-view-wrapper",class:_vm.workflowClasses,on:{"touchstart":_vm.mouseDown,"touchend":_vm.mouseUp,"touchmove":_vm.mouseMoveNodeWorkflow,"mousedown":_vm.mouseDown,"mouseup":_vm.mouseUp,"wheel":_vm.wheelScroll}},[_c('div',{staticClass:"node-view-background",style:(_vm.backgroundStyle),attrs:{"id":"node-view-background"}}),_c('div',{staticClass:"node-view",style:(_vm.workflowStyle),attrs:{"id":"node-view"}},_vm._l((_vm.nodes),function(nodeData){return _c('node',{key:_vm.getNodeIndex(nodeData.name),attrs:{"id":'node-' + _vm.getNodeIndex(nodeData.name),"name":nodeData.name,"isReadOnly":_vm.isReadOnly,"instance":_vm.instance,"isActive":!!_vm.activeNode && _vm.activeNode.name === nodeData.name,"hideActions":_vm.pullConnActive},on:{"duplicateNode":_vm.duplicateNode,"deselectAllNodes":_vm.deselectAllNodes,"deselectNode":_vm.nodeDeselectedByName,"nodeSelected":_vm.nodeSelectedByName,"removeNode":_vm.removeNode,"runWorkflow":_vm.runWorkflow,"moved":_vm.onNodeMoved,"run":_vm.onNodeRun}})}),1)]),_c('DataDisplay',{on:{"valueChanged":_vm.valueChanged}}),(!_vm.createNodeActive && !_vm.isReadOnly)?_c('div',{staticClass:"node-creator-button",attrs:{"title":_vm.$locale.baseText('nodeView.addNode')},on:{"click":() => _vm.openNodeCreator('add_node_button')}},[_c('n8n-icon-button',{attrs:{"size":"xlarge","icon":"plus"}})],1):_vm._e(),_c('node-creator',{attrs:{"active":_vm.createNodeActive},on:{"nodeTypeSelected":_vm.nodeTypeSelected,"closeNodeCreator":_vm.closeNodeCreator}}),_c('div',{class:{
			'zoom-menu': true,
			'regular-zoom-menu': !_vm.isDemo,
			'demo-zoom-menu': _vm.isDemo,
			expanded: !_vm.sidebarMenuCollapsed,
		}},[_c('button',{staticClass:"button-white",attrs:{"title":_vm.$locale.baseText('nodeView.zoomToFit')},on:{"click":_vm.zoomToFit}},[_c('font-awesome-icon',{attrs:{"icon":"expand"}})],1),_c('button',{staticClass:"button-white",attrs:{"title":_vm.$locale.baseText('nodeView.zoomIn')},on:{"click":function($event){return _vm.zoomIn()}}},[_c('font-awesome-icon',{attrs:{"icon":"search-plus"}})],1),_c('button',{staticClass:"button-white",attrs:{"title":_vm.$locale.baseText('nodeView.zoomOut')},on:{"click":function($event){return _vm.zoomOut()}}},[_c('font-awesome-icon',{attrs:{"icon":"search-minus"}})],1),(_vm.nodeViewScale !== 1 && !_vm.isDemo)?_c('button',{staticClass:"button-white",attrs:{"title":_vm.$locale.baseText('nodeView.resetZoom')},on:{"click":function($event){return _vm.resetZoom()}}},[_c('font-awesome-icon',{attrs:{"icon":"undo","title":_vm.$locale.baseText('nodeView.resetZoom')}})],1):_vm._e()]),_vm._v(" '' "),(!_vm.isReadOnly)?_c('div',{staticClass:"workflow-execute-wrapper"},[_c('n8n-button',{attrs:{"loading":_vm.workflowRunning,"size":"large","icon":"play-circle","title":_vm.$locale.baseText('nodeView.executesTheWorkflowFromTheStartOrWebhookNode'),"type":_vm.workflowRunning ? 'light' : 'primary'},on:{"click":function($event){$event.stopPropagation();return _vm.runWorkflow()}}}),(_vm.workflowRunning === true && !_vm.executionWaitingForWebhook)?_c('n8n-icon-button',{staticClass:"stop-execution",attrs:{"icon":"stop","size":"large","type":"light","title":_vm.stopExecutionInProgress
					? _vm.$locale.baseText('nodeView.stoppingCurrentExecution')
					: _vm.$locale.baseText('nodeView.stopCurrentExecution'),"loading":_vm.stopExecutionInProgress},on:{"click":function($event){$event.stopPropagation();return _vm.stopExecution()}}}):_vm._e(),(_vm.workflowRunning === true && _vm.executionWaitingForWebhook === true)?_c('n8n-icon-button',{staticClass:"stop-execution",attrs:{"icon":"stop","size":"large","title":_vm.$locale.baseText('nodeView.stopWaitingForWebhookCall'),"type":"light"},on:{"click":function($event){$event.stopPropagation();return _vm.stopWaitingForWebhook()}}}):_vm._e(),(!_vm.isReadOnly && _vm.workflowExecution && !_vm.workflowRunning)?_c('n8n-icon-button',{attrs:{"title":_vm.$locale.baseText('nodeView.deletesTheCurrentExecutionData'),"icon":"trash","size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.clearExecutionData()}}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }