
import Vue from 'vue';

export default Vue.extend({
	name: 'ErrorView',
	props: {
		messageKey: {
			type: String,
			required: true,
		},
		errorCode: {
			type: Number,
		},
		redirectTextKey: {
			type: String,
		},
		redirectPage: {
			type: String,
		},
	},
	methods: {
		onButtonClick() {
			this.$router.push({ name: this.redirectPage });
		},
	},
});
