
import Vue from 'vue';

export default Vue.extend({
	name: 'LandingView',
	methods: {
		onButtonClick() {
			this.$router.push("/workflow");
		},
	},
	computed: {
		role() {
			return this.$store.state.users.permission;
		},
	},
});
