
import { PropType } from 'vue/types/v3-component-props';
import { defineComponent } from 'vue';
export default defineComponent({
	name: 'MapTree',
	props: {
		nodes: {
			type: [Array, Object],
		},
		label: {
			type: String,
			default: "",
		},
		currentNode: {
			type: [Object, Array],
		},
		path: {
			type: String,
			default: "",
		},
		depth: {
			type: Number,
			default: 0,
		},
		id: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			showChildren: true as boolean,
		};
	},
	computed: {
		// @ts-ignore
		indent() {
			return {
				// @ts-ignore
				"padding-left": `${(this.depth * 24) / this.depth}px`,
			};
		},
		// @ts-ignore
		dataKey() {
			// @ts-ignore
			if (this.currentNode) {
				// @ts-ignore
				// @ts-ignore
				if (this.currentNode.key)
					// @ts-ignore
					return this.currentNode.key;

				else
					// @ts-ignore
					return this.currentNode.name;
			}


			return '';
		},
		// @ts-ignore
		keySelector() {
			// @ts-ignore
			if (this.currentNode) {
				// @ts-ignore
				// @ts-ignore
				if (this.currentNode.key)
					// @ts-ignore
					return this.currentNode.key.split(".").pop().split('["').pop().split('"]').shift();

				else
					// @ts-ignore
					return this.currentNode.name;
			}


			return '';
		},
		// @ts-ignore
		dataPath() {

			// @ts-ignore
			if (this.path !== "") return `${this.path}.${this.label}`;

			if (this.label)
				// @ts-ignore
				return `${this.label}`;

			return '';
			// @ts-ignore
		},
		// @ts-ignore
		pathSelector() {

			// @ts-ignore
			if (this.path !== "") return `${this.path}.${this.label}`;

			if (this.label)
				// @ts-ignore
				return `${this.label}`;

			return 'root';
			// @ts-ignore
		},
	},
	methods: {
		collapseNode(path: any, label: any) {
			this.showChildren = !this.showChildren;
			let payload = {
				clickedItemPath: path + "." + label,
				clickedItemLabel: label,
				collapsed: this.showChildren,
			};
			this.$emit("collapsed", payload);
		},
		handleCollapse(payload: any) {
			this.$emit("collapsed", payload);
		},
	},
	mounted() {
		// @ts-ignore

	},
});
