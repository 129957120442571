
import mixins from "vue-typed-mixins";
import { genericHelpers } from "@/components/mixins/genericHelpers";
import { workflowHelpers } from "@/components/mixins/workflowHelpers";
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowRun } from '@/components/mixins/workflowRun';
import { Mentionable } from 'vue-mention';
import { INodeUi } from "@/Interface";

export default mixins(genericHelpers, workflowHelpers, workflowRun, nodeHelpers)
	.extend({
		name: "Calculator",
		components: {
			Mentionable,
		},
		props: ['value', 'parameterName'],
		computed: {
			node (): INodeUi | null {
				return this.$store.getters.activeNode;
			},
			dataColumns(): any[] {
				let inputData = this.getNodeInputData(this.node, this.runIndex, this.outputIndex);
				if (inputData.length === 0 || !Array.isArray(inputData)) {
					return [];
				}
				const tableHeaders = Object.keys(inputData[0].json);
				const suffix = "} ";
				return tableHeaders.map((header) => {
					return {
						label: header,
						value: `${header}${suffix}`,
					};
				});
			},
			text: {
				get(): string {
					return (this as any).value;
				},
				set(value: string) {
					const parameterData = {
						name: `parameters.${this.parameterName}`,
						node: (this as any).node!.name,
						value: value,
					};
					(this as any).$emit('valueChanged', parameterData);
				},
			},
		},
		data() {
			return {
				runIndex: 0,
				outputIndex: 0,
				calcButtons: [
					{
						text: "plus",
						value: "+",
					},
					{
						text: "minus",
						value: "-",
					},
					{
						text: "multiply",
						value: "*",
					},
					{
						text: "divide",
						value: "/",
					},
					{
						text: "brckt_open",
						value: "(",
					},
					{
						text: "brckt_close",
						value: ")",
					},
					{
						text: "sqrt",
						value: "sqrt(",
					},
					{
						text: "sqr",
						value: "^",
					},
				],
			};
		},
		methods: {
			addOp(value: string) {
				const input = this.$refs.formulaInput as HTMLInputElement;
				let startPos: any = input.selectionStart,
					endPos: any = input.selectionEnd,
					cursorPos = startPos,
					tmpStr = input.value;

				this.text = tmpStr.substring(0, startPos) + value + tmpStr.substring(endPos, tmpStr.length);

				// move cursor:
				setTimeout(() => {
					cursorPos += value.length;
					input.selectionStart = input.selectionEnd = cursorPos;
					(this.$refs.formulaInput as HTMLElement).focus();
				}, 10);
			},
		},
	});
