
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "PushConnectionTracker",
	computed: {
		...mapGetters(["pushConnectionActive"]),
	},
});
