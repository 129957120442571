
import mixins from 'vue-typed-mixins';
import { copyPaste } from './mixins/copyPaste';
import { showMessage } from './mixins/showMessage';

export default mixins(copyPaste, showMessage).extend({
	props: {
		label: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		copyContent: {
			type: String,
		},
		copyButtonText: {
			type: String,
		},
		successMessage: {
			type: String,
		},
	},
	methods: {
		copy(): void {
			this.copyToClipboard(this.$props.copyContent);

			this.$showMessage({
				title: this.$locale.baseText('credentialEdit.credentialEdit.showMessage.title'),
				message: this.$props.successMessage,
				type: 'success',
			});
		},
	},
});
