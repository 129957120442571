
import { Workflow } from 'n8n-workflow';
import { v4 as uuidv4 } from 'uuid';
import mixins from 'vue-typed-mixins';
import { workflowHelpers } from './mixins/workflowHelpers';
import {
	GenericValue,
	IDataObject,
} from 'n8n-workflow';

import {
	IVariableSelectorOption,
} from '@/Interface';


export default mixins(workflowHelpers).extend({
	name: 'NodeMapping',
	props: [
		'node', // NodeUi
		'nodeType', // INodeTypeDescription
		'nodeValues', // INodeParameters
		'parameters', // INodeProperties
		'path',
	],
	components: {},
	data() {
		return {
			inputs: [] as any[],
			outputs: [] as any[],
			inputOutputs: [] as any[],
		};
	},
	computed: {
		hasInputs(): boolean {
			const inputs = this.inputs;
			return inputs.length > 0;
		},
		hasOutputs(): boolean {
			const outputs = this.outputs;
			return outputs.length > 0;
		},
		workflow(): Workflow {
			return this.getWorkflow();
		},
		inputOptions(): any {
			const connections = this.workflow.connectionsByDestinationNode;
			if (connections[this.node.name]) {
				return connections[this.node.name].main[0] || [];
			}
			return [];
		},
		outputOptions(): any {
			const connections = this.workflow.connectionsBySourceNode;
			if (connections[this.node.name]) {
				return connections[this.node.name].main[0] || [];
			}
			return [];
		},
	},
	mounted() {
		this.updateLocalInputOutput();

	},
	methods: {
		getNodeParameters(nodeName: string, path: string, skipParameter?: string, filterText?: string): IVariableSelectorOption[] | null {
			const node = this.workflow.getNode(nodeName);
			if (node === null) {
				return null;
			}

			const returnParameters: IVariableSelectorOption[] = [];
			for (const parameterName in node.parameters) {
				if (parameterName === skipParameter) {
					// Skip the parameter
					continue;
				}

				if (filterText !== undefined && parameterName.toLowerCase().indexOf(filterText) === -1) {
					// If filter is set apply it
					continue;
				}

				returnParameters.push.apply(returnParameters, this.jsonDataToFilterOption(node.parameters[parameterName], path, parameterName, filterText, undefined, undefined, skipParameter));
			}

			return returnParameters;
		},
		jsonDataToFilterOption(inputData: IDataObject | GenericValue | IDataObject[] | GenericValue[] | null, parentPath: string, propertyName: string, filterText?: string, propertyIndex?: number, displayName?: string, skipKey?: string): IVariableSelectorOption[] {
			let fullpath = `${parentPath}["${propertyName}"]`;
			if (propertyIndex !== undefined) {
				fullpath += `[${propertyIndex}]`;
			}

			const returnData: IVariableSelectorOption[] = [];
			if (inputData === null) {
				returnData.push(
					{
						name: propertyName,
						key: fullpath,
						value: '[null]',
					} as IVariableSelectorOption,
				);
				return returnData;
			} else if (Array.isArray(inputData)) {
				let newPropertyName = propertyName;
				let newParentPath = parentPath;
				if (propertyIndex !== undefined) {
					newParentPath += `["${propertyName}"]`;
					newPropertyName = propertyIndex.toString();
				}

				const arrayData: IVariableSelectorOption[] = [];

				for (let i = 0; i < inputData.length; i++) {
					arrayData.push.apply(arrayData, this.jsonDataToFilterOption(inputData[i], newParentPath, newPropertyName, filterText, i, `[Item: ${i}]`, skipKey));
				}

				returnData.push(
					{
						name: displayName || propertyName,
						options: arrayData,
						key: fullpath,
						allowParentSelect: true,
						dataType: 'array',
					} as IVariableSelectorOption,
				);
			} else if (typeof inputData === 'object') {
				const tempValue: IVariableSelectorOption[] = [];

				for (const key of Object.keys(inputData)) {
					tempValue.push.apply(tempValue, this.jsonDataToFilterOption((inputData as IDataObject)[key], fullpath, key, filterText, undefined, undefined, skipKey));
				}

				if (tempValue.length) {
					returnData.push(
						{
							name: displayName || propertyName,
							options: this.sortOptions(tempValue),
							key: fullpath,
							allowParentSelect: true,
							dataType: 'object',
						} as IVariableSelectorOption,
					);
				}
			} else {
				if (filterText !== undefined && propertyName.toLowerCase().indexOf(filterText) === -1) {
					return returnData;
				}

				// Skip is currently only needed for leafs so only check here
				if (this.getPathNormalized(skipKey) !== this.getPathNormalized(fullpath)) {
					returnData.push(
						{
							name: propertyName,
							key: fullpath,
							value: inputData,
						} as IVariableSelectorOption,
					);
				}
			}

			return returnData;
		},
		getPathNormalized(path: string | undefined): string {
			if (path === undefined) {
				return '';
			}
			const pathArray = path.split('.');

			const finalArray = [];
			let item: string;
			for (const pathPart of pathArray) {
				const pathParts = pathPart.match(/\[.*?\]/g);
				if (pathParts === null) {
					// Does not have any brakets so add as it is
					finalArray.push(pathPart);
				} else {
					// Has brakets so clean up the items and add them
					if (pathPart.charAt(0) !== '[') {
						// Does not start with a braket so there is a part before
						// we have to add
						finalArray.push(pathPart.substr(0, pathPart.indexOf('[')));
					}

					for (item of pathParts) {
						item = item.slice(1, -1);
						if (['"', "'"].includes(item.charAt(0))) {
							// Is a string
							item = item.slice(1, -1);
							finalArray.push(item);
						} else {
							// Is a number
							finalArray.push(`[${item}]`);
						}
					}
				}
			}

			return finalArray.join('|');
		},
		sortOptions(options: IVariableSelectorOption[] | null): IVariableSelectorOption[] | null {
			if (options === null) {
				return null;
			}
			return options.sort((a: IVariableSelectorOption, b: IVariableSelectorOption) => {
				const aHasOptions = a.hasOwnProperty('options');
				const bHasOptions = b.hasOwnProperty('options');

				if (bHasOptions && !aHasOptions) {
					// When b has options but a not list it first
					return 1;
				} else if (!bHasOptions && aHasOptions) {
					// When a has options but b not list it first
					return -1;
				}

				// Else simply sort alphabetically
				if (a.name < b.name) { return -1; }
				if (a.name > b.name) { return 1; }
				return 0;
			});
		},

		inputsFromMapping(mapping: string) {
			if (mapping !== undefined) {
				this.inputOptions.forEach((input: any) => {
					if (mapping.includes(input.node)) {
						this.inputs.push({ id: uuidv4(), value: input.node });
					}
				});
			}

		},
		outputsFromMapping(mapping: string) {
			if (mapping !== undefined) {
				this.outputOptions.forEach((output: any) => {
					if (mapping.includes(output.node)) {
						this.outputs.push({ id: uuidv4(), value: output.node });
					}
				});
			}
		},
		addInput() {
			this.inputs.push({ id: uuidv4(), value: '' });
		},
		addOutput() {
			this.outputs.push({ id: uuidv4(), value: '' });
		},
		updateLocalInputOutput() {
			const jsonMappingParams = this.getNodeParameters(this.node!.name, `$node["${this.node!.name}"].parameter`, undefined);
			//@ts-ignore
			const inputParam = jsonMappingParams[0].value.split(',');
			if (inputParam) {
				this.inputs = inputParam.map((input: string) => {
					return {
						id: uuidv4(),
						value: input,
					};
				});
			}
			//@ts-ignore
			const outputParam = jsonMappingParams[1].value.split(',');
			if (outputParam) {
				this.outputs = outputParam.map((output: string) => {
					return {
						id: uuidv4(),
						value: output,
					};
				});
			}
			const inputOutputs: any = {
				inputs: [...new Set(this.inputs.map((input: any) => input.value).filter(v => v !== ''))],
				outputs: [...new Set(this.outputs.map((output: any) => output.value).filter(v => v !== ''))],
			};
			this.$store.commit('setMappingOnNode', { name: this.node.name, data: inputOutputs });
		},
		updateInputOutput() {
			const inputOutputs: any = {
				inputs: [...new Set(this.inputs.map((input: any) => input.value).filter(v => v !== ''))],
				outputs: [...new Set(this.outputs.map((output: any) => output.value).filter(v => v !== ''))],
			};
			const parameterDataInput = {
				name: `parameters.inputNodes`,
				node: this.$store.getters.activeNode.name,
				value: inputOutputs.inputs.join(','),
			};
			const parameterDataOutput = {
				name: `parameters.outputNodes`,
				node: this.$store.getters.activeNode.name,
				value: inputOutputs.outputs.join(','),
			};

			this.$emit('valueChanged', parameterDataInput);
			this.$emit('valueChanged', parameterDataOutput);
			this.$store.commit('setMappingOnNode', { name: this.node.name, data: inputOutputs });
		},
	},
	watch: {
		node(newValue) {
			this.updateLocalInputOutput();
		},
	},
});
