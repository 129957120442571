
import {
	INodeTypeDescription,
	IWebhookDescription,
	NodeHelpers,
} from 'n8n-workflow';

import { WEBHOOK_NODE_TYPE } from '@/constants';
import { copyPaste } from '@/components/mixins/copyPaste';
import { showMessage } from '@/components/mixins/showMessage';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import mixins from 'vue-typed-mixins';

export default mixins(
	copyPaste,
	showMessage,
	workflowHelpers,
)
	.extend({
		name: 'NodeWebhooks',
		props: [
			'node', // NodeUi
			'nodeType', // INodeTypeDescription
		],
		data () {
			return {
				isMinimized: false,
				showUrlFor: 'test',
				emailAddress: '',
				loadingEmailAdress: false,
			};
		},
		computed: {
			webhooksNode (): IWebhookDescription[] {
				if (this.nodeType === null || this.nodeType.webhooks === undefined) {
					return [];
				}

				return (this.nodeType as INodeTypeDescription).webhooks!.filter(webhookData => webhookData.restartWebhook !== true);
			},
		},
		async mounted () {
			await this.getNewEmailadress();
		},
		methods: {
			copyEmailAddress (): void {
				this.copyToClipboard(this.emailAddress);
				this.$showMessage({
					title: "Email copié",
					type: 'success',
				});
			},
			getValue (webhookData: IWebhookDescription, key: string): string {
				if (webhookData[key] === undefined) {
					return 'empty';
				}
				try {
					return this.resolveExpression(webhookData[key] as string) as string;
				} catch (e) {
					return this.$locale.baseText('nodeWebhooks.invalidExpression');
				}
			},
			getWebhookUrl (webhookData: IWebhookDescription): string {
				if (webhookData.restartWebhook === true) {
					return '$resumeWebhookUrl';
				}
				let baseUrl = this.$store.getters.getWebhookUrl;


				const workflowId = this.$store.getters.workflowId;
				const path = this.getValue(webhookData, 'path');
				const isFullPath = this.getValue(webhookData, 'isFullPath') as unknown as boolean || false;

				return NodeHelpers.getNodeWebhookUrl(baseUrl, workflowId, this.node, path, isFullPath);
			},

			getTestWebhookUrl (webhookData: IWebhookDescription): string {
				if (webhookData.restartWebhook === true) {
					return '$resumeWebhookUrl';
				}

				let baseUrl = this.$store.getters.getWebhookTestUrl;
				const workflowId = this.$store.getters.workflowId;
				const path = this.getValue(webhookData, 'path');
				const isFullPath = this.getValue(webhookData, 'isFullPath') as unknown as boolean || false;

				return NodeHelpers.getNodeWebhookUrl(baseUrl, workflowId, this.node, path, isFullPath);
			},

			getWebhookUrlDisplay (webhookData: IWebhookDescription): string {
				return this.getWebhookUrl(webhookData);
			},
			async getNewEmailadress() {
				this.loadingEmailAdress = true;
				const webhookUrl = this.getWebhookUrl(this.webhooksNode[0]);
				const testWebhookUrl = this.getTestWebhookUrl(this.webhooksNode[0]);
				const workflowId = this.$store.getters.workflowId;

				// register both webhook urls
				await this.$store.dispatch('workflows/getEmailAddress', { getWebhookUrl: testWebhookUrl, workflowId });
				const response = await this.$store.dispatch('workflows/getEmailAddress', { getWebhookUrl: webhookUrl, workflowId });

				this.loadingEmailAdress = false;
				this.emailAddress = response.email;
			},
		},
		watch: {
			node () {
				this.isMinimized = this.nodeType.name !== WEBHOOK_NODE_TYPE;
			},
		},
	});
