
import mixins from 'vue-typed-mixins';
import { showMessage } from './mixins/showMessage';

export default mixins(showMessage).extend({
	name: 'InlineNameEdit',
	props: {
		name: {
			type: String,
		},
		subtitle: {
			type: String,
		},
		type: {
			type: String,
		},
	},
	data() {
		return {
			isNameEdit: false,
		};
	},
	methods: {
		onNameEdit(value: string) {
			this.$emit('input', value);
		},
		enableNameEdit() {
			if(!this.isNiagCreds()) {
				this.isNameEdit = true;

				setTimeout(() => {
					const input = this.$refs.nameInput as HTMLInputElement;
					if (input) {
						input.focus();
					}
				}, 0);
			}
		},
		disableNameEdit() {
			if (!this.name) {
				this.$emit('input', `Untitled ${this.type}`);

				this.$showToast({
					title: 'Error',
					message: `${this.type} name cannot be empty`,
					type: 'warning',
				});
			}

			this.isNameEdit = false;
		},
		isNiagCreds () {
			return this.name.toLowerCase().startsWith('niagara default auth');
		},
	},
});
