

import Vue from 'vue';
import { WAIT_TIME_UNLIMITED } from '@/constants';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeBase } from '@/components/mixins/nodeBase';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';

import {
	INodeTypeDescription,
	ITaskData,
	NodeHelpers,
} from 'n8n-workflow';

import NodeIcon from '@/components/NodeIcon.vue';

import mixins from 'vue-typed-mixins';

import { get } from 'lodash';
import { getStyleTokenValue, getTriggerNodeServiceName } from './helpers';
import { INodeUi, XYPosition } from '@/Interface';

export default mixins(externalHooks, nodeBase, nodeHelpers, workflowHelpers).extend({
	name: 'Node',
	components: {
		NodeIcon,
	},
	computed: {
		nodeRunData(): ITaskData[] {
			return this.$store.getters.getWorkflowResultDataByNodeName(this.data.name);
		},
		hasIssues (): boolean {
			if (this.data.issues !== undefined && Object.keys(this.data.issues).length) {
				return true;
			}
			return false;
		},
		workflowDataItems (): number {
			const workflowResultDataNode = this.nodeRunData;
			if (workflowResultDataNode === null) {
				return 0;
			}

			return workflowResultDataNode.length;
		},
		canvasOffsetPosition() {
			return this.$store.getters.getNodeViewOffsetPosition;
		},
		getTriggerNodeTooltip (): string | undefined {
			if (this.nodeType !== null && this.nodeType.hasOwnProperty('eventTriggerDescription')) {
				const nodeName = this.$locale.shortNodeType(this.nodeType.name);
				const { eventTriggerDescription } = this.nodeType;
				return this.$locale.nodeText().eventTriggerDescription(nodeName, eventTriggerDescription);
			} else {
				return this.$locale.baseText(
					'node.waitingForYouToCreateAnEventIn',
					{
						interpolate: {
							nodeType: this.nodeType && getTriggerNodeServiceName(this.nodeType.displayName),
						},
					},
				);
			}
		},
		isPollingTypeNode (): boolean {
			return !!(this.nodeType && this.nodeType.polling);
		},
		isExecuting (): boolean {
			return this.$store.getters.executingNode === this.data.name;
		},
		isSingleActiveTriggerNode (): boolean {
			const nodes = this.$store.getters.workflowTriggerNodes.filter((node: INodeUi) => {
				const nodeType =  this.$store.getters.nodeType(node.type, node.typeVersion) as INodeTypeDescription | null;
				return nodeType && nodeType.eventTriggerDescription !== '' && !node.disabled;
			});

			return nodes.length === 1;
		},
		isTriggerNode (): boolean {
			return !!(this.nodeType && this.nodeType.group.includes('trigger'));
		},
		isTriggerNodeTooltipEmpty () : boolean {
			return this.nodeType !== null ? this.nodeType.eventTriggerDescription === '' : false;
		},
		isNodeDisabled (): boolean | undefined {
			return this.node && this.node.disabled;
		},
		nodeType (): INodeTypeDescription | null {
			return this.data && this.$store.getters.nodeType(this.data.type, this.data.typeVersion);
		},
		node (): INodeUi | undefined { // same as this.data but reactive..
			return this.$store.getters.nodesByName[this.name] as INodeUi | undefined;
		},
		nodeClass (): object {
			return {
				'node-box': true,
				disabled: this.data.disabled,
				executing: this.isExecuting,
			};
		},
		nodeIssues (): string {
			if (this.data.issues === undefined) {
				return '';
			}

			const nodeIssues = NodeHelpers.nodeIssuesToString(this.data.issues, this.data);

			return `${this.$locale.baseText('node.issues')}:<br />&nbsp;&nbsp;- ` + nodeIssues.join('<br />&nbsp;&nbsp;- ');
		},
		nodeDisabledIcon (): string {
			if (this.data.disabled === false) {
				return 'pause';
			} else {
				return 'play';
			}
		},
		position (): XYPosition {
			return this.node ? this.node.position : [0, 0];
		},
		showDisabledLinethrough(): boolean {
			return !!(this.data.disabled && this.nodeType && this.nodeType.inputs.length === 1 && this.nodeType.outputs.length === 1);
		},
		nodePosition (): object {
			const returnStyles: {
				[key: string]: string;
			} = {
				left: this.position[0] + 'px',
				top: this.position[1] + 'px',
			};

			return returnStyles;
		},
		shortNodeType (): string {
			return this.$locale.shortNodeType(this.data.type);
		},
		nodeTitle (): string {
			if (this.data.name === 'Start') {
				return this.$locale.headerText({
					key: `headers.start.displayName`,
					fallback: 'Start',
				});
			}

			return this.data.name;
		},
		waiting (): string | undefined {
			const workflowExecution = this.$store.getters.getWorkflowExecution;

			if (workflowExecution && workflowExecution.waitTill) {
				const lastNodeExecuted = get(workflowExecution, 'data.resultData.lastNodeExecuted');
				if (this.name === lastNodeExecuted) {
					const waitDate = new Date(workflowExecution.waitTill);
					if (waitDate.toISOString() === WAIT_TIME_UNLIMITED) {
						return this.$locale.baseText('node.theNodeIsWaitingIndefinitelyForAnIncomingWebhookCall');
					}
					return this.$locale.baseText(
						'node.nodeIsWaitingTill',
						{
							interpolate: {
								date: waitDate.toLocaleDateString(),
								time: waitDate.toLocaleTimeString(),
						 	},
						},
					);
				}
			}

			return;
		},
		workflowRunning (): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
		nodeStyle (): object {
			let borderColor = getStyleTokenValue('--color-foreground-xdark');

			if (this.data.disabled) {
				borderColor = getStyleTokenValue('--color-foreground-base');
			}
			else if (!this.isExecuting) {
				if (this.hasIssues) {
					borderColor = getStyleTokenValue('--color-danger');
				}
				else if (this.waiting) {
					borderColor = getStyleTokenValue('--color-secondary');
				}
				else if (this.workflowDataItems) {
					borderColor = getStyleTokenValue('--color-success');
				}
			}

			const returnStyles: {
				[key: string]: string;
			} = {
				'border-color': borderColor,
			};

			return returnStyles;
		},
		isSelected (): boolean {
			return this.$store.getters.getSelectedNodes.find((node: INodeUi) => node.name === this.data.name);
		},
		shiftOutputCount (): boolean {
			return !!(this.nodeType && this.nodeType.outputs.length > 2);
		},
		shouldShowTriggerTooltip () : boolean {
			return !!this.node &&
				this.isTriggerNode &&
				!this.isPollingTypeNode &&
				!this.isNodeDisabled &&
				this.workflowRunning &&
				this.workflowDataItems === 0  &&
				this.isSingleActiveTriggerNode &&
				!this.isTriggerNodeTooltipEmpty &&
				!this.hasIssues &&
				!this.dragging;
		},
 	},
	watch: {
		isActive(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.setSubtitle();
			}
		},
		canvasOffsetPosition() {
			if (this.showTriggerNodeTooltip) {
				this.showTriggerNodeTooltip = false;
				setTimeout(() => {
					this.showTriggerNodeTooltip = this.shouldShowTriggerTooltip;
				}, 200);
			}
		},
		shouldShowTriggerTooltip(shouldShowTriggerTooltip) {
			if (shouldShowTriggerTooltip) {
				setTimeout(() => {
					this.showTriggerNodeTooltip = this.shouldShowTriggerTooltip;
				}, 2500);
			} else {
				this.showTriggerNodeTooltip = false;
			}
		},
		nodeRunData(newValue) {
			this.$emit('run', {name: this.data.name, data: newValue, waiting: !!this.waiting});
		},
	},
	mounted() {
		this.setSubtitle();
		setTimeout(() => {
			this.$emit('run', {name: this.data.name, data: this.nodeRunData, waiting: !!this.waiting});
		}, 0);
	},
	data () {
		return {
			isTouchActive: false,
			nodeSubtitle: '',
			showTriggerNodeTooltip: false,
			dragging: false,
		};
	},
	methods: {
		setSubtitle() {
			this.nodeSubtitle = this.getNodeSubtitle(this.data, this.nodeType, this.getWorkflow()) || '';
		},
		disableNode () {
			this.disableNodes([this.data]);
			this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'disable', workflow_id: this.$store.getters.workflowId });
		},
		executeNode () {
			this.$emit('runWorkflow', this.data.name, 'Node.executeNode');
			this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'execute', workflow_id: this.$store.getters.workflowId });
		},
		deleteNode () {
			this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'delete', workflow_id: this.$store.getters.workflowId });

			Vue.nextTick(() => {
				// Wait a tick else vue causes problems because the data is gone
				this.$emit('removeNode', this.data.name);
			});
		},
		duplicateNode () {
			this.$telemetry.track('User clicked node hover button', { node_type: this.data.type, button_name: 'duplicate', workflow_id: this.$store.getters.workflowId });
			Vue.nextTick(() => {
				// Wait a tick else vue causes problems because the data is gone
				this.$emit('duplicateNode', this.data.name);
			});
		},

		setNodeActive () {
			this.$store.commit('setActiveNode', this.data.name);
		},
		touchStart () {
			if (this.isTouchDevice === true && this.isMacOs === false && this.isTouchActive === false) {
				this.isTouchActive = true;
				setTimeout(() => {
					this.isTouchActive = false;
				}, 2000);
			}
		},
	},
});

