


import mixins from 'vue-typed-mixins';
import { restApi } from '@/components/mixins/restApi';

export default mixins(
	restApi,
)
	.extend({
		name: 'BinaryDataDisplayEmbed',
		props: [
			'binaryData', // IBinaryDisplayData
		],
		data() {
			return {
				isLoading: true,
				embedSource: '',
				error: false,
			};
		},
		async mounted() {
			if(!this.binaryData.id) {
				this.embedSource = 'data:' + this.binaryData.mimeType + ';base64,' + this.binaryData.data;
				this.isLoading = false;
				return;
			}

			try {
				const bufferString = await this.restApi().getBinaryBufferString(this.binaryData!.id!);
				this.embedSource = 'data:' + this.binaryData.mimeType + ';base64,' + bufferString;
				this.isLoading = false;
			} catch (e) {
				this.isLoading = false;
				this.error = true;
			}
		},
		methods: {
			embedClass(): string[] {
				// @ts-ignore
				if (this.binaryData! !== null && this.binaryData!.mimeType! !== undefined && (this.binaryData!.mimeType! as string).startsWith('image')) {
					return ['image'];
				}
				return ['other'];
			},
		},
	});
