
import { MAX_TAG_NAME_LENGTH } from "@/constants";
import Vue from "vue";

export default Vue.extend({
	props: {
		disabled: {
			default: false,
		},
		search: {
			default: "",
		},
	},
	data() {
		return {
			maxLength: MAX_TAG_NAME_LENGTH,
		};
	},
	methods: {
		onAddNew() {
			this.$emit("createEnable");
		},
		onSearchChange(search: string) {
			this.$emit("searchChange", search);
		},
	},
});
